import React from "react"
import {Animated} from "react-animated-css"

import withStyles from "@material-ui/styles/withStyles"

import colors from "../colors"
import Layout from "../components/layout"

import biz from "../images/contact/biz.jpg"

import fb from "../images/fb.png"
import linkedin from "../images/linkedin.png"
import twitter from "../images/twitter.png"

const styles = {
    wrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        fontSize: "2rem",
        textAlign: "center",

        "@media (max-width:600px)": {
            fontSize: "1.2rem",
        },

        "& .mail": {
            color: colors.bleu,
            fontWeight: "bold",
            marginTop: "3rem",
        },

        "& .tel": {
            color: colors.gris,
        },

        "& h2": {
            "@media (max-width:600px)": {
                fontSize: "1.5rem",
                paddingTop: "3rem",
            },
        },
    },

    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",

        "@media (max-width:600px)": {
            flexDirection: "column",
        },
    },

    biz: {
        borderRadius: "50%",
        overflow: "hidden",

        "@media (max-width:600px)": {
            display: "none",
        },
    },
}

class Contact extends React.Component {
    render() {
        const {classes} = this.props

        return (
            <Layout>
                <div className={classes.wrapper}>
                    <Animated animationIn="fadeIn" animationOut="fadeOutLeft">
                        <h2>CONFIEZ-NOUS VOTRE MISSION</h2>
                    </Animated>
                    <div className={classes.container}>
                        <Animated animationIn="fadeIn" animationOut="bounceOut">
                            <a className="mail" href="mailto:contact@techexmachina.com">
                                contact@techexmachina.com
                            </a>
                            <br/>
                            <div>
                                Tech Ex Machina
                                <br/>
                                Rue des prairies
                                <br/>
                                19360 Malemort
                                <br/>
                                France
                            </div>
                            <br/>
                            <div>
                                N° Siret : 82968265700038 <br/>
                                R.C.S. Brive 829682657 <br/>
                                TVA intracommunautaire : FR47829682657
                            </div>
                            <br/>

                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <a
                                    href="https://www.facebook.com/TechExMachina"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img width="32px" alt="Facebook" src={fb}/>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/tech-ex-machina"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img width="32px" alt="Linked In" src={linkedin}/>
                                </a>
                                <a
                                    href="https://twitter.com/TechExMachina"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img width="32px" alt="Twitter" src={twitter}/>
                                </a>
                            </div>
                        </Animated>
                        <div className={classes.biz}>
                            <Animated animationIn="fadeIn" animationOut="fadeOutRight">
                                <img
                                    src={biz}
                                    alt="Contactez nous !"
                                    style={{height: "400px", width: "400px"}}
                                />
                            </Animated>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(Contact)
